<template>
  <div>
    <q-form ref="editForm">
      <c-card title="매뉴얼" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-if="editable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="data"
              :mappingType="saveType"
              label="저장"
              icon="save"
              @beforeAction="saveData"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-textarea
              :editable="editable"
              type="editor"
              label="매뉴얼 내용"
              name="content"
              :editheight="40"
              v-model="data.content">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:20px">
            <c-upload
              :attachInfo="attachInfo"
              :editable="editable"
              label="첨부파일">
            </c-upload>
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'manual-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sysMenuId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'MANUAL_FILE',
        taskKey: '',
      },
      saveUrl: transactionConfig.sys.manual.insert.url,
      saveType: 'POST',
      insertUrl: '',
      updateUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      detailUrl: '',
      data: {
        sysMenuId: '',  // 메뉴 SEQ
        content: '',  // 매뉴얼 내용
        useFlag: 'Y',  // 사용여부
        existManual: 'N',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sys.manual.get.url;
      this.insertUrl = transactionConfig.sys.manual.insert.url;
      this.updateUrl = transactionConfig.sys.manual.update.url;
      this.getData();
    },
    getData() {
      if (this.popupParam.sysMenuId) {
        this.updateMode = true;
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sysMenuId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data.existManual == 'N') {
            this.data = {
              sysMenuId: this.popupParam.sysMenuId,  // 메뉴 SEQ
              content: '',  // 매뉴얼 내용
              useFlag: 'Y',  // 사용여부
            };
            this.saveUrl = this.insertUrl;
            this.saveType = 'POST';
          } else {
            this.data = _result.data;
            this.saveUrl = this.updateUrl;
            this.saveType = 'PUT';
          }
          this.$set(this.attachInfo, 'taskKey', this.popupParam.sysMenuId)
        },
        () => {
        });
      }
    },
    saveData() {
      let saveMsg = '저장하시겠습니까?';
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      this.getData();
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
  }
};
</script>
